<template>
    <section class="create-survival">
        <div class="d-lg-flex">
                <b-form-group :label="$t('title')" label-for="title">
                    <input type="text" name="title" v-model="title" class="form-control" :placeholder="$t('title')">
                </b-form-group>

                <b-form-group :label="$t('startDate')" label-for="startDate">
                    <datepicker name="startDate" input-class="form-control input-bg-black" :placeholder="$t('startDate')" :open-date="openDate" minimum-view="day" :format="customFormatter" v-model="startDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group :label="$t('endDate')" label-for="endDate">
                    <datepicker name="endDate" input-class="form-control input-bg-black" :placeholder="$t('endDate')" :open-date="openDate" minimum-view="day" :format="customFormatter" v-model="endDate" :disabled-dates="disabledDates" />
                </b-form-group>
        </div>
        <div>
            <div v-if="loading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
                <b-button id="survival-create" class="btn-sm text-uppercase" variant="success" block @click.prevent="createSurvival" :disabled="disableButton" >{{ $t('create') }}</b-button>
                <event-table :events="events" @selected-events="onEventsSelected" />
            </div>
        </div>
</section>
</template>

<script>
import eventTable from "@/pages/share/event-table"
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'create-survival',
  components: {
    eventTable,
    Datepicker
  },
  data() {
    return {
        loading: false,
        startDate: this.getNextWeekDay(new Date(), 1),
        endDate: this.addDays(this.getNextWeekDay(new Date(), 1), 6),
        openDate: new Date(),
        disabledDates: {
            to: new Date(),
        },
        disableButton: false,
        title: "",
        events: [],
        eventIds: [],
    };
  },
  created () {
    this.$bobwinHub.$on('eventsReceived', this.eventsReceived);
    this.$bobwinHub.$on('addSurvivalReceived', this.addSurvivalReceived);
    this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('eventsReceived', this.eventsReceived);
    this.$bobwinHub.$off('addSurvivalReceived', this.addSurvivalReceived);
  },
  methods: {
    init() {
        this.loading = true;
        this.CallHub({task: 'GetAllEvents', callback: 'eventsReceived'});
    },
    eventsReceived (response) {
        this.events = JSON.parse(response.data);
        this.loading = false;
    },
    onEventsSelected(values) {
        this.eventIds = values;
    },
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    customFormatter(date) {
      return this.$moment(date).format('YYYYMMDD');
    },
    removeTimeFromDateUTC(datetime) {
        let date = new Date(datetime);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    },
    getEndOfDate(datetime) {
        return this.removeTimeFromDateUTC(this.addDays(datetime, 1));
    },
    getNextWeekDay(date, d) {
        if (d) {
            var next = date;
            next.setDate(date.getDate() - date.getDay() + 7 + d);
            return next;
        }
    },
    createSurvival() {
        const eventsList = this.eventIds.map((e) => { return {Id: e}});
        if (eventsList.length != 7) {
            this.ShowErrorMessage("Please choose 7 events.");
            return;
        }
        this.disableButton = true;
        const groupDto = {
            Name: this.title, 
            Type: "Survival", 
            StartDate: this.removeTimeFromDateUTC(this.startDate),
            EndDate: this.getEndOfDate(this.endDate),
            Events: eventsList
        };

        this.CallHub({task: 'AddSurvivalEvents', callback: 'addSurvivalReceived', data: JSON.stringify(groupDto)});
    },
    addSurvivalReceived(response) {
        if (response != null && response.result == 'success') {
            this.ShowSuccessMessage("Created");
        } else {
            this.ShowErrorMessage(response?.message);
        }
        this.disableButton = false;
    }
  }
}
</script>
