<template>
    <div class="col-12 grid-margin">
        <div class="card">
            <div class="card-body">
                <span class="card-title h3">{{title}}</span>
                <perfect-scrollbar>
                    <div class="overflow-height">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th v-for="(col, idx) in columns" :key="idx"> {{col}} </th>
                                </tr>
                                </thead>
                                <tbody class="sortable">
                                <tr v-for="(row, index) in rows" :key="index" >
                                    <td v-for="(data, i) in row" :key="i">
                                        <span class="pl-2">{{data}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'custom-table',
  props: ["title", "columns", "rows"]
}
</script>

<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
.table th, .table td {
    white-space: normal !important;
}
</style>