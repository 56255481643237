<template>
    <section class="survival-table">
        <div v-if="loading">
            <div class="pixel-loader"></div>
        </div>
        <div v-else>
            <b-button class="btn-sm text-uppercase" variant="success" block @click.prevent="init" >{{ $t('reload') }}</b-button>
            <ve-table 
                id="survival-grid" 
                :columns="columns" 
                :table-data="tableData"
                row-key-field-name="id"
                :checkbox-option="checkboxOption"
                :fixed-header="true"
                :max-height="600"
            />
            <div class="table-pagination bg-white pt-3">
                <ve-pagination
                    :total="totalCount"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    @on-page-number-change="pageNumberChange"
                    @on-page-size-change="pageSizeChange"
                />
            </div>
        </div>
    </section>
</template>

<script>
import "vue-easytable/libs/theme-default/index.css";
import { VeTable, VePagination } from "vue-easytable"; 

export default {
  name: 'survival-table',
  components: {
    VeTable, 
    VePagination
  },
  data() {
    return {
        loading: true,
        groups: [],
        checkboxOption: {
            // row select change event
            selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
                window.console.log(row, isSelected, selectedRowKeys);
                this.$emit('selected-events', selectedRowKeys);
            },
            // selected all change event
            selectedAllChange: ({ isSelected, selectedRowKeys }) => {
                window.console.log(isSelected, selectedRowKeys);
            },
        },
        columns: [],
        pageIndex: 1,
        pageSize: 10,
        DB_DATA: [],
        searchData: {
            category: [],
            sub: [],
            groups: [],
            types: [],
        }
    };
  },
  computed: {
    tableData() {
        const { pageIndex, pageSize } = this;
        return this.DB_DATA.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
    },
    totalCount() {
        return this.DB_DATA.length;
    },
  },
  created () {
    this.$bobwinHub.$on('survivalEventsReceived', this.survivalEventsReceived);
    this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('survivalEventsReceived', this.survivalEventsReceived);
  },
  watch: {
    searchData: {
        handler: function () {
            this.search();
        },
        immediate: true,
        deep: true,
    },
  },
  methods: {
    init () {
        this.GetSurvivalEventList();
    },
    GetSurvivalEventList() {
        this.loading = true;
        this.CallHub({task: 'GetSurvivalEventList', callback: 'survivalEventsReceived', data: JSON.stringify(new Date())});
    },
    search() {
        const { category, sub, groups, types } = this.searchData;
        const filtered = this.groups.filter((x) => 
                (groups.length === 0 || groups.includes(x.name))
                && (types.length === 0 || types.includes(x.type))
        );
        this.DB_DATA = [];
        filtered.forEach((g) => {
            const events = g.events.filter((x) => 
                    (category.length === 0 || category.includes(x.eventCategoryName)) 
                    && (sub.length === 0 || sub.includes(x.subcategory.name))
            );
            this.fillUpData(g, events);
        });
    },
    fillUpData (g, events) {
        events.forEach((e) => {
            this.DB_DATA.push({
                group: g.name,
                type: g.type,
                start: this.GetFormattedDatetime(g.startDate),
                end: this.GetFormattedDatetime(g.endDate),
                sport: e.eventCategoryName,
                league: e.subcategory.name,
                title: e.title, 
                eventStart: this.GetFormattedDatetime(e.startDate),
                id: e.id, 
            });
        });
    },
    pageNumberChange(pageIndex) {
        this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
        this.pageIndex = 1;
        this.pageSize = pageSize;
    },
    survivalEventsReceived (response) {
      this.groups = JSON.parse(response.data);
      const groups = [];
      const categoreis = [];
      const sub = [];
      const types = [];
      this.DB_DATA = [];
      this.groups.forEach((g) => {
        this.fillUpData(g, g.events);
        if (!groups.includes(g.name)) groups.push(g.name);
        if (!types.includes(g.type)) types.push(g.type);
        g.events.forEach((e) => {
            if (!categoreis.includes(e.eventCategoryName)) categoreis.push(e.eventCategoryName);
            if (!sub.includes(e.subcategory.name)) sub.push(e.subcategory.name);
        });
      });
      this.columns = [
        {
            field: "",
            key: "a",
            title: "",
            width: 50,
            align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                window.console.log(row, column, h);
                return ++rowIndex;
            },
        },
        { field: "", key: "b", type: "checkbox", title: "", width: 50, align: "center" },
        {
            field: "group",
            key: "group",
            title: "Group",
            align: "left",
            filter: {
                filterList: groups.map((val, idx) => { return { value: idx, label: val, selected: false }}),
                isMultiple: true,
                filterConfirm: (filterList) => {
                    const labels = filterList
                        .filter((x) => x.selected)
                        .map((x) => x.label);
                    this.searchData.groups = labels;
                },
                filterReset: (filterList) => {
                    window.console.log(filterList);
                    this.searchData.groups = [];
                },
                maxHeight: 120,
            },
        },
        {
            field: "type",
            key: "type",
            title: "Type",
            align: "left",
            filter: {
                filterList: types.map((val, idx) => { return { value: idx, label: val, selected: false }}),
                isMultiple: true,
                filterConfirm: (filterList) => {
                    const labels = filterList
                        .filter((x) => x.selected)
                        .map((x) => x.label);
                    this.searchData.types = labels;
                },
                filterReset: (filterList) => {
                    window.console.log(filterList);
                    this.searchData.types = [];
                },
                maxHeight: 120,
            },
        },
        { field: "start", key: "start", title: "Start Date", align: "right" },
        { field: "end", key: "end", title: "End Date", align: "right" },
        {
            field: "sport",
            key: "sport",
            title: "Sport",
            align: "left",
            filter: {
                filterList: categoreis.map((val, idx) => { return { value: idx, label: val, selected: false }}),
                isMultiple: true,
                // filter confirm hook
                filterConfirm: (filterList) => {
                    const labels = filterList
                        .filter((x) => x.selected)
                        .map((x) => x.label);
                    this.searchData.category = labels;
                },
                // filter reset hook
                filterReset: (filterList) => {
                    window.console.log(filterList);
                    this.searchData.category = [];
                },
                // max height
                maxHeight: 120,
            },
        },
        {
            field: "league",
            key: "league",
            title: "League",
            align: "left",
            filter: {
                filterList: sub.map((val, idx) => { return { value: idx, label: val, selected: false }}),
                isMultiple: true,
                // filter confirm hook
                filterConfirm: (filterList) => {
                    const labels = filterList
                        .filter((x) => x.selected)
                        .map((x) => x.label);
                    this.searchData.sub = labels;
                },
                // filter reset hook
                filterReset: (filterList) => {
                    window.console.log(filterList);
                    this.searchData.sub = [];
                },
                // max height
                maxHeight: 120,
            },
        },
        { field: "title", key: "title", title: "Title", align: "left" },
        { field: "eventStart", key: "eventStart", title: "Event Date", align: "right" },
        { field: "id", key: "id", title: "Id", align: "center" }];
      this.loading = false;
    },
  }
}
</script>
