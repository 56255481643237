<template>
  <b-card>

    <div class="mb-2">
      <label for="email-type">Email Type:</label>
      <b-form-select id="email-type" v-model="emailType" :options="typeOptions"></b-form-select>
    </div>

    <div class="mb-2">
      <label for="email-subject">Subject:</label>
      <b-form-input id="email-subject" v-model="subject" placeholder="Subject"></b-form-input>
    </div>

    <div class="mb-2">
      <label for="email-subject">Body:</label>
      <medium-editor
        v-model="content"
        prefill="Hi good bettors!<br /><br /><br /><br />Best regards,<br />The GoodBettor Team"
        :options="options"
      />
    </div>

    <div class="mt-3">
      <b-button squared variant="outline-warning" class="mr-2" @click="onSend" :disabled="!subject || !content">Send Test Email to Admins</b-button>
      <b-button squared variant="success" @click="onSend(true)" :disabled="!subject || !content">Send</b-button>
    </div>

  </b-card>
</template>

<script lang="js">
export default {
  data: () => ({
    emailType: 'Announcement',
    typeOptions: ['Announcement', 'Community'],
    subject: '',
    content: 'Hello,',
    options: {
      toolbar: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'quote',
          'h1',
          'h2',
          'h3',
          'pre',
          'unorderedlist',
        ]
      }
    }
  }),
  created () {
    this.$bobwinHub.$on('emailSent', response => {
      this.ShowNotification('Email has been sent.', response.result == 'success' ? 'Info' : 'Error');
    })
  },
  beforeDestroy () {
    this.$root.$off('openFeedbackDialog', this.openDialog);
    this.$bobwinHub.$off('feedbackAdded', this.feedbackAdded);
  },
  methods: {
    onSend(sendToPublic) {
      var param = {
        sendToPublic: sendToPublic==true,
        Subject: this.subject,
        Content: this.content,
        EmailType: this.emailType
      }

      if(sendToPublic == true) {
        this.$swal({
          title: 'Do you want to send this email?',
          icon: 'info',
          animation: false,
          showCancelButton: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          if (result.value) {
            this.sendEmail(param);
          }
        });
      } else {
        this.sendEmail(param);
      }
    },
    sendEmail(param){
      this.CallHub({task: 'SendEmail', callback: 'emailSent', data: JSON.stringify(param)});
    }
  }
}
</script>