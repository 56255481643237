<template>
    <section class="bankroll-strategy">
        <div >
            <div class="d-flex">
                <b-form-group :label="$t('startDate')" label-for="startDate">
                    <datepicker name="startDate" input-class="form-control input-bg-black" :placeholder="$t('startDate')" :open-date="startDate" minimum-view="day" :format="customFormatter" v-model="startDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group :label="$t('endDate')" label-for="endDate">
                    <datepicker name="endDate" input-class="form-control input-bg-black" :placeholder="$t('endDate')" :open-date="endDate" minimum-view="day" :format="customFormatter" v-model="endDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group label="League" label-for="league">
                    <b-form-input v-model="league" type="text" name="league" id="league" placeholder="League Name" autocomplete="off" />
                </b-form-group>
            </div>
        </div>
        <div >
            <div class="d-flex">
                <b-form-group label="From Odds" label-for="oddsFrom">
                    <b-form-input v-model="oddsFrom" type="text" name="oddsFrom" id="oddsFrom" placeholder="From Odds" autocomplete="off" />
                </b-form-group>
                <b-form-group label="To Odds" label-for="oddsTo">
                    <b-form-input v-model="oddsTo" type="text" name="oddsTo" id="oddsTo" placeholder="To Odds" autocomplete="off" />
                </b-form-group>
                <b-form-group label="Initial Balance" label-for="initBalance">
                    <b-form-input v-model="initBalance" type="text" name="initBalance" id="initBalance" placeholder="Initial Balance" autocomplete="off" />
                </b-form-group>
                <b-form-group label="Unit Size" label-for="initUnitSize">
                    <b-form-input v-model="initUnitSize" type="text" name="initUnitSize" id="initUnitSize" placeholder="Unit Size" autocomplete="off" />
                </b-form-group>
            </div>
            <b-button id="request-raw-data" class="btn-sm text-uppercase" variant="success" block @click.prevent="getRawData" :disabled="disableButton" >Get Data</b-button>
            <b-button id="request-simulation" class="btn-sm text-uppercase" variant="success" block @click.prevent="clickSimulateBets" :disabled="betEvents.length == 0" >Simulate</b-button>
            <b-button id="request-simulation100" class="btn-sm text-uppercase" variant="success" block @click.prevent="clickSimulateBets100" :disabled="betEvents.length == 0" >Simulate 100</b-button>
        </div>
        <div >
            <div class="d-block">
                <span>Max # of Lost: {{ maxLost }}</span><br />
                <span>Max Lost Bet: {{maxLostAmount.toFixed(2)}}</span><br />
                <span>Max # of Won: {{maxWon}}</span><br />
                <span>Max Won Bet: {{maxWonAmount.toFixed(2)}}</span><br />
            </div>
        </div>
        <div >
            <custom-table :columns="simulateColumns" :rows="simulationRows" :title="title" />
        </div>
        <div >
            <custom-table :columns="logsColumns" :rows="logs" :title="title" />
        </div>
    </section>
</template>

<script>
import CustomTable from './custom-table.vue';
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'bankroll-strategy',
  components: {
    CustomTable,
    Datepicker
  },
  data() {
    return {
        title: '',
        columns: ['odds range', 'win rate(# of win)', 'profit', 'roi'],
        rows: [],
        loading: false,
        runSimulation: false,
        disableButton: false,
        oddsFrom: 2.0,
        oddsTo: 3.0,
        initBalance: 1000,
        simulationProfit: 0,
        simulationRoi: 0,
        simulationWinRate: 0,
        simulationTotal: 0,
        simulationRows: [],
        simulateColumns: ['balance', '# of bet', 'max # of lost', 'max lost bet', 'max # of won', 'max won bet'],
        league: 'NBA',
        openDate: new Date(),
        disabledDates: {
            to: new Date('2022-11-01'),
        },
        startDate: new Date('2023-04-01'),
        endDate: this.addDays(this.getNextWeekDay(new Date(), 1), 6),
        betEvents: [],
        datacollection: {},
        options: {},
        balance: 1000,
        initUnitSize: 1,
        maxLost: 0,
        maxLostAmount: 0,
        maxWon: 0,
        maxWonAmount: 0,
        logs: [],
        logsColumns: ['date', 'status', 'unit size', 'odds', 'profit', 'balance', '# of won', 'max won', '# of lost', 'max lost']
    };
  },
  created () {
    this.$bobwinHub.$on('oddsRawDataReceived', this.oddsRawDataReceived);
    //this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('oddsRawDataReceived', this.oddsRawDataReceived);
  },
  methods: {
    init() {
    },
    getRawData() {
        this.disableButton = true;
        this.CallHub({task: 'GetOddsRawData', callback: 'oddsRawDataReceived', data: JSON.stringify({SportLeague: this.league, StartDate: this.startDate, EndDate: this.endDate})});
    },
    getWeekNumber(date) {
        const formattedDate = this.$moment(date, 'YYYY-MM-DD');
        const weekNumber = formattedDate.isoWeek();
        return weekNumber;
    },
    clickSimulateBets100() {
        this.reset();
        for(var i = 0; i < 100; ++i) {
            this.runSimilationBet();
        }
    },
    clickSimulateBets() {
        this.reset();
        this.runSimilationBet();
    },
    runSimilationBet() {
        const result = this.simulateBets(Number(this.initBalance), Number(this.initUnitSize));
        this.logs = result.logs;
        delete result.logs;
        this.simulationRows.push(result);

        if (this.maxLost < result.maxLost) {
            this.maxLost = result.maxLost;
        }
        if (this.maxLostAmount < result.maxLostAmount) {
            this.maxLostAmount = result.maxLostAmount;
        }
        if (this.maxWon < result.maxWon) {
            this.maxWon = result.maxWon;
        }
        if (this.maxWonAmount < result.maxWonAmount) {
            this.maxWonAmount = result.maxWonAmount;
        }
    },
    getRangedOdds(items) {
        const from = this.oddsFrom;
        const to = this.oddsTo;
        const filtered = items.filter(obj => obj.odds.filter(o => o.odds >= from && o.odds <= to).length > 0);
        return filtered;
    },
    simulateBets(balance, initUnitSize) {
        const eventsByDate = this.groupByDate(this.betEvents);
        const chosenList = [];
        eventsByDate.forEach((val) => {
            const filtered = this.getRangedOdds(val.items);
            if (filtered.length > 0) {
                const selected = this.getRandomNumber(filtered.length - 1);
                const best = this.findBiggestOdds(filtered[selected].odds);
                chosenList.push({ date: filtered[selected].date, odds: best } );
            }
/*            const selected = this.getRandomNumber(val.items.length - 1);
            const best = this.findBiggestOdds(val.items[selected].odds);
            chosenList.push({ date: val.items[selected].date, odds: best } );*/
        });
        let unitSize = initUnitSize;
        let maxLost = 0;
        let lost = 0;
        let maxLostAmount = 0;
        let lostAmount = 0;
        let maxWon = 0;
        let won = 0;
        let maxWonAmount = 0;
        const logs = [];
        chosenList.forEach(e => {
            const paidUnitSize = unitSize;
            balance -= unitSize;
            let profit = 0;
            if (e.odds.isWon) {
                profit = unitSize * e.odds.odds;
                if (maxWonAmount < profit) {
                    maxWonAmount = profit;
                }
                unitSize = initUnitSize;
                lost = 0;
                lostAmount = 0;
                won++;
                if (maxWon < won) {
                    maxWon = won;
                }
            } else {
                lostAmount += unitSize;
                if (lostAmount > maxLostAmount) {
                    maxLostAmount = lostAmount;
                }
                unitSize *= 2;
                lost++;
                won = 0;
                if (maxLost < lost) {
                    maxLost = lost;
                }
            }
            balance += profit;
            const log = {
                date: e.date,
                status: e.odds.isWon ? 'Won' : 'Lost',
                unitSize: paidUnitSize,
                odds: e.odds.odds,
                profit: profit,
                balance: balance.toFixed(2),
                won: won,
                maxWon: maxWon.toFixed(2),
                lost: lost,
                maxLost: maxLost.toFixed(2)
            };
            logs.push(log);
        });
        return { 
            balance: balance.toFixed(2),
            bet: chosenList.length,
            maxLost: maxLost,
            maxLostAmount: maxLostAmount,
            maxWon: maxWon,
            maxWonAmount: maxWonAmount,
            logs: logs
        };
    },
    reset() {
        this.simulationRows = [];
        this.logs = [];
        this.maxLost = 0;
        this.maxLostAmount = 0;
        this.maxWon = 0;
        this.maxWonAmount = 0; 
    },
    oddsRawDataReceived(response) {
        const data = JSON.parse(response.data);
        this.betEvents = this.addExtra(data);
        this.disableButton = false;
        this.reset();
    },
    addExtra(events) {
        events.forEach(e => {
            e["date"] = this.convertUTCToPDTAndFormat(e.startDate);
        });
        return events;
    },
    findBiggestOdds(arr) {
        return arr.reduce((maxOddsObj, currentObj) => {
            return (currentObj.odds > maxOddsObj.odds) ? currentObj : maxOddsObj;
        });
    },
    getRandomNumber(max) {
       return Math.floor(Math.random() * max);
    },
    convertUTCToPDTAndFormat(utcDateString) {
        const utcDate = new Date(utcDateString);
        const pdtOffset = -7 * 60 * 60 * 1000;
        const pdtDate = new Date(utcDate.getTime() + pdtOffset);
        const formattedPdtDate = pdtDate.toISOString().split('T')[0];
        return formattedPdtDate;
    },
    groupByDate(inputArray) {
        const grouped = inputArray.reduce((acc, obj) => {
        // Extract the date and remove the time component
        const date = obj.date;

        // If the date is not in the accumulator, add a new array with the object
        if (!acc[date]) {
            acc[date] = [obj];
        }
        // If the date is in the accumulator, push the object into the existing array
        else {
            acc[date].push(obj);
        }

            return acc;
        }, {});
        // Convert the object to an array with date keys
        const result = Object.keys(grouped).map(date => ({
            date,
            items: grouped[date]
        }));

        return result;
    },
    getSimulatedList() {
        const simulatedList = [];
        this.betEvents.forEach((e) => {
            let found = null;
            e.odds.forEach((o) => {
                if (o.odds >= this.oddsFrom && o.odds <= this.oddsTo) {
                    found = o;
                }
            });
            if (found) {
                simulatedList.push({ startDate: e.startDate, odds: found, title: e.eventTitle});
            }
        });
        return simulatedList;
    },
    findConsistentGap(obj) {
        // Get the keys and convert them to an array of numbers
        const keys = Object.keys(obj).map(Number).sort((a, b) => a - b);

        // Check if there are at least two keys
        if (keys.length < 2) {
            return 0; // Return 0 for objects with 0 or 1 keys as there's no gap
        }

        // Calculate the consistent gap between the first two keys
        const gap = keys[1] - keys[0];

        // Verify if the gap is consistent for all consecutive keys
        for (let i = 2; i < keys.length; i++) {
            if (keys[i] - keys[i - 1] !== gap) {
            throw new Error("The keys do not have a consistent gap.");
            }
        }

        return gap;
    },
    getEndOfDate(datetime) {
        return this.removeTimeFromDateUTC(this.addDays(datetime, 1));
    },
    getNextWeekDay(date, d) {
        if (d) {
            var next = date;
            next.setDate(date.getDate() - date.getDay() + 7 + d);
            return next;
        }
    },
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    customFormatter(date) {
      return this.$moment(date).format('YYYYMMDD');
    },
    removeTimeFromDateUTC(datetime) {
        let date = new Date(datetime);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    },
  }
}
</script>
<style lang="scss">
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>