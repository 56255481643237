<template>
  <section class="userDetail">
    <b-modal id="user-dialog-modal" class="user-dialog-modal" :title="title" size="xl" button-size="lg" ok-only @click="handleOk">
        <user-dashbaord v-if="userGuid" :user-guid="userGuid" />
    </b-modal>
  </section>
</template>

<script>
import userDashbaord from '../share/user-dashbaord.vue';

export default {
    name: 'user-dialog',
    components: {
        userDashbaord
    },
    data() {
        return {
            loading: true,
            userGuid: '',
            title: ''
        }
    },
    created () {
      this.$root.$on('openUserDialog', this.openUserDialog);
    },
    beforeDestroy() {
        this.$root.$off("openUserDialog", this.openUserDialog);
    },
    methods: {
        handleOk(bvModalEvt){
            bvModalEvt.preventDefault();
        },
        openUserDialog (userGuid, title) {
            this.userGuid = userGuid;
            this.title = title;
            this.$bvModal.show('user-dialog-modal');
        },
    }
}
</script>
