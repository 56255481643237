<template>
    <section class="odds-analysis">
        <div >
            <div class="d-flex">
                <b-form-group :label="$t('startDate')" label-for="startDate">
                    <datepicker name="startDate" input-class="form-control input-bg-black" :placeholder="$t('startDate')" :open-date="startDate" minimum-view="day" :format="customFormatter" v-model="startDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group :label="$t('endDate')" label-for="endDate">
                    <datepicker name="endDate" input-class="form-control input-bg-black" :placeholder="$t('endDate')" :open-date="endDate" minimum-view="day" :format="customFormatter" v-model="endDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group label="League" label-for="league">
                    <b-form-input v-model="league" type="text" name="league" id="league" placeholder="League Name" autocomplete="off" />
                </b-form-group>
            </div>
        </div>
        <div >
            <div v-if="loading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
                <b-button id="request-statistics" class="btn-sm text-uppercase" variant="success" block @click.prevent="getStatisticsByOdds" :disabled="disableButton" >Simulate</b-button>
                <custom-table :columns="columns" :rows="rows" :title="title" />
            </div>
        </div>
        <div >
            <div class="d-flex">
                <b-form-group label="From Odds" label-for="oddsFrom">
                    <b-form-input v-model="oddsFrom" type="text" name="oddsFrom" id="oddsFrom" placeholder="From Odds" autocomplete="off" />
                </b-form-group>
                <b-form-group label="To Odds" label-for="oddsTo">
                    <b-form-input v-model="oddsTo" type="text" name="oddsTo" id="oddsTo" placeholder="To Odds" autocomplete="off" />
                </b-form-group>
                <b-form-group label="Bet Amount" label-for="betAmount">
                    <b-form-input v-model="betAmount" type="text" name="betAmount" id="betAmount" placeholder="Bet Amount" autocomplete="off" />
                </b-form-group>
            </div>
            <b-button id="request-raw-data" class="btn-sm text-uppercase" variant="success" block @click.prevent="getRawData" :disabled="disableButton" >Simulate</b-button>
        </div>
        <div >
            <lineChart :datacollection="weekDataCollection" :options="options" />
            <barChart :datacollection="datacollection" :options="options" />
        </div>
        <div >
            <div class="d-block">
                <span>Profit: {{simulationProfit}} ({{simulationTotal * betAmount}} in total bet)</span><br />
                <span>ROI: {{simulationRoi.toFixed(2)}}%</span><br />
                <span>Win Rate: {{simulationWinRate.toFixed(2)}}%</span><br />
                <span>Total bets: {{simulationTotal}}</span><br />
            </div>
        </div>
        <div >
            <custom-table :columns="simulateColumns" :rows="simulationRows" :title="title" />
        </div>
    </section>
</template>

<script>
import CustomTable from './custom-table.vue';
import Datepicker from 'vuejs-datepicker'
import lineChart from '@/components/charts/chartjs/lineChart.vue'
import barChart from '@/components/charts/chartjs/barChart.vue'

export default {
  name: 'odds-analysis',
  components: {
    CustomTable,
    lineChart,
    barChart,
    Datepicker
  },
  data() {
    return {
        title: '',
        columns: ['odds range', 'win rate(# of win)', 'profit', 'roi'],
        rows: [],
        loading: false,
        runSimulation: false,
        disableButton: false,
        oddsFrom: 5.0,
        oddsTo: 9.0,
        betAmount: 100,
        simulationProfit: 0,
        simulationRoi: 0,
        simulationWinRate: 0,
        simulationTotal: 0,
        simulationRows: [],
        simulateColumns: ['event date', 'title', 'odds', 'bet amount', 'won/lost', 'profit'],
        league: 'NBA',
        openDate: new Date(),
        disabledDates: {
            to: new Date('2022-11-01'),
        },
//        startDate: this.getNextWeekDay(new Date('2022-12-01'), 1),
        startDate: new Date('2023-04-01'),
        endDate: this.addDays(this.getNextWeekDay(new Date(), 1), 6),
        betEvents: [],
        datacollection: {},
        weekDataCollection: {},
        options: {},
    };
  },
  created () {
    this.$bobwinHub.$on('oddsAnalysisReceived', this.oddsAnalysisReceived);
    this.$bobwinHub.$on('simulationBetReceived', this.simulationBetReceived);
    this.$bobwinHub.$on('oddsRawDataReceived', this.oddsRawDataReceived);
    //this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('oddsAnalysisReceived', this.oddsAnalysisReceived);
    this.$bobwinHub.$off('simulationBetReceived', this.simulationBetReceived);
    this.$bobwinHub.$off('oddsRawDataReceived', this.oddsRawDataReceived);
  },
  methods: {
    init() {
    },
    getStatisticsByOdds() {
        this.loading = true;
        this.disableButton = true;
        this.CallHub({task: 'GetStatisticsByOdds', callback: 'oddsAnalysisReceived', data: JSON.stringify({SportLeague: this.league, StartDate: this.startDate, EndDate: this.endDate})});
    },
    getRawData() {
        this.disableButton = true;
        this.CallHub({task: 'GetOddsRawData', callback: 'oddsRawDataReceived', data: JSON.stringify({SportLeague: this.league, StartDate: this.startDate, EndDate: this.endDate})});
    },
    simulationBet() {
        this.runSimulation = true;
        this.CallHub({task: 'SimulationBet', callback: 'simulationBetReceived', data: JSON.stringify({FromOdds: this.oddsFrom, ToOdds: this.oddsTo, BetAmount: this.betAmount})});
    },
    getWeekNumber(date) {
        // Format the input date
        const formattedDate = this.$moment(date, 'YYYY-MM-DD');
        
        // Get the week number
        const weekNumber = formattedDate.isoWeek();
        
        // Return the week number
        return weekNumber;
    },
    oddsRawDataReceived(response) {
        const data = JSON.parse(response.data);
        this.betEvents = data;
        const simulatedList = this.getSimulatedList();
        let dic = {};
        const weekDic = {};
        this.simulationTotal = 0;
        this.simulationProfit = 0;
        let won = 0;
        this.simulationRows = [];
        simulatedList.forEach((e) => {
            const startDate = new Date(e.startDate);
            const key = this.GetFormattedDate(startDate);
            const weekKey = this.getWeekNumber(startDate);
            const existingValue = dic[key] ? dic[key] : 0;
            const existingWeekValue = weekDic[weekKey] ? weekDic[weekKey] : 0;
            const profit = e.odds.isWon ? (e.odds.odds - 1) * this.betAmount : -this.betAmount;
            dic[key] = existingValue + profit;
            weekDic[weekKey] = existingWeekValue + profit;
            this.simulationProfit += profit;
            this.simulationTotal++;
            if (e.odds.isWon) {
                won++;
            }
            this.simulationRows.push([e.startDate, e.title, e.odds.odds, this.betAmount, e.odds.isWon ? 'Won' : 'Lost', this.GetFloorValue(profit, 2)]);
        });
        this.simulationRows.sort((a,b) => new Date(b[0]) - new Date(a[0]));
        this.simulationWinRate = this.simulationTotal == 0 ? 0 : won / this.simulationTotal * 100; 
        this.simulationRoi = this.simulationTotal == 0 ? 0 : this.simulationProfit / (this.simulationTotal * this.betAmount) * 100;

        this.datacollection = this.getDataCollection(Object.keys(dic), Object.values(dic));
        this.weekDataCollection = this.getDataCollection(Object.keys(weekDic), Object.values(weekDic));
        this.options = this.getOptions();
        this.disableButton = false;
    },
    getSimulatedList() {
        const simulatedList = [];
        this.betEvents.forEach((e) => {
            let found = null;
            e.odds.forEach((o) => {
                if (o.odds >= this.oddsFrom && o.odds <= this.oddsTo) {
                    found = o;
                }
            });
            if (found) {
                simulatedList.push({ startDate: e.startDate, odds: found, title: e.eventTitle});
            }
        });
        return simulatedList;
    },
    simulationBetReceived(response) {
        const data = JSON.parse(response.data);
        this.simulationRoi = data.roi;
        this.simulationProfit = data.profit;
        this.simulationWinRate = data.winRate;
        this.simulationTotal = data.total;
        this.runSimulation = false;
    },
    findConsistentGap(obj) {
        // Get the keys and convert them to an array of numbers
        const keys = Object.keys(obj).map(Number).sort((a, b) => a - b);

        // Check if there are at least two keys
        if (keys.length < 2) {
            return 0; // Return 0 for objects with 0 or 1 keys as there's no gap
        }

        // Calculate the consistent gap between the first two keys
        const gap = keys[1] - keys[0];

        // Verify if the gap is consistent for all consecutive keys
        for (let i = 2; i < keys.length; i++) {
            if (keys[i] - keys[i - 1] !== gap) {
            throw new Error("The keys do not have a consistent gap.");
            }
        }

        return gap;
    },
    oddsAnalysisReceived(response) {
        const data = JSON.parse(response.data);
        this.rows = [];
        const gap = this.findConsistentGap(data.winRate);

        Object.entries(data.winRate).forEach(([key, value]) => {
            const min = data.roiRate[key].minOdds;
            const max = data.roiRate[key].maxOdds;
            const profit = data.roiRate[key].profit;
            const profitRate = data.roiRate[key].profitRate;
            const count = data.roiRate[key].count;
            const range = key + '~' + (Number(key) + gap) + '% (' + min + '~' + max + ')';
            const winrate = value + '%(' + count + ')';
            this.rows.push([range, winrate, profit, profitRate + '%']);
        });
        this.loading = false;
        this.disableButton = false;
    },
    getEndOfDate(datetime) {
        return this.removeTimeFromDateUTC(this.addDays(datetime, 1));
    },
    getNextWeekDay(date, d) {
        if (d) {
            var next = date;
            next.setDate(date.getDate() - date.getDay() + 7 + d);
            return next;
        }
    },
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    customFormatter(date) {
      return this.$moment(date).format('YYYYMMDD');
    },
    removeTimeFromDateUTC(datetime) {
        let date = new Date(datetime);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    },
    getDataCollection(keys, values) {
      const borderColors = [];
      const color = 'rgba(83,245,89,1)';
      for(let i = 0; i < values.length; ++i) {
        borderColors.push(color);
      }
      return {
        labels: keys,
        datasets: [{
          label: 'Profit',
          data: values,
          borderColor: borderColors,
          borderWidth: 2,
          fill: false
        }]};
    },
    getOptions() {
      return {
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }],
          xAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }]
        },
        legend: { display: true },
        elements: { point: { radius: 0 } },
        responsive: true,
        maintainAspectRatio: false,
      };
    }
  }
}
</script>
<style lang="scss">
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>