<template>
    <section class="user-table">
        <div v-if="loading">
            <div class="pixel-loader"></div>
        </div>
        <div v-else>
            <b-button class="btn-sm text-uppercase" variant="success" block @click.prevent="init" >{{ $t('reload') }}</b-button>
            <ve-table 
                id="user-grid" 
                :columns="columns" 
                :table-data="tableData"
                row-key-field-name="id"
                :fixed-header="true"
                :max-height="600"
                :sort-option="sortOption"
            />
            <div class="table-pagination bg-white pt-3">
                <ve-pagination
                    :total="totalCount"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    @on-page-number-change="pageNumberChange"
                    @on-page-size-change="pageSizeChange"
                />
            </div>
            <user-dialog v-if="userClickEvent == null"/>
            <ve-icon v-if="false" />
        </div>
    </section>
</template>

<script>
import "vue-easytable/libs/theme-default/index.css"; 
import { VeTable, VePagination, VeIcon } from "vue-easytable";
import UserDialog from '../dialogs/user-dialog.vue';

export default {
  name: 'user-table',
  components: {
    VeTable,
    VePagination,
    VeIcon,
    UserDialog 
  },
  data() {
    return {
        loading: true,
        users: [],
        searchValue: "",
        columns: [],
        pageIndex: 1,
        pageSize: 10,
        DB_DATA: [],
        sortOption: {
            sortChange: (params) => {
                this.sortChange(params);
            },
        },
    };
  },
  props:["userClickEvent"],
  computed: {
    tableData() {
        const { pageIndex, pageSize } = this;
        return this.DB_DATA.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
    },
    totalCount() {
        return this.DB_DATA.length;
    },
  },
  created () {
    this.$bobwinHub.$on('usersReceived', this.usersReceived);
    this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('usersReceived', this.usersReceived);
  },
  methods: {
    init () {
        this.initColumns();
        this.getAllUsers();
    },
    initColumns() {
        this.columns = [
        {
            field: "",
            key: "a",
            title: "",
            width: 50,
            align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                window.console.log(row, column, h);
                return ++rowIndex;
            }
        },
        {
            field: "",
            key: "e",
            title: "Action",
            width: "",
            center: "left",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <span>
                        <b-button
                            class="btn-sm text-uppercase"
                            on-click={() => this.click({row, column, rowIndex, h})}
                        >Open</b-button>
                    </span>
                );
            }
        },
        {
            field: "username",
            key: "username",
            title: "Username",
            align: "left",
            filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                    window.console.log(showFn, h);
                    return (
                        <div class="custom-name-filter">
                            <input
                                value={this.searchValue}
                                on-input={(e) => (this.searchValue = e.target.value)}
                                placeholder="Search name"
                            />
                            <div class="custom-name-filter-operation">
                                <span
                                    class="name-filter-cancel"
                                    on-click={() => this.searchReset(closeFn)}
                                >
                                    reset
                                </span>
                                <span
                                    class="name-filter-confirm"
                                    on-click={() => this.searchConfirm(closeFn)}
                                >
                                    ok
                                </span>
                            </div>
                        </div>
                    );
                },
                // custom filter icon
                filterIcon: () => {
                    return <ve-icon name="search" />;
                },
            }
        },
        {
            field: "email",
            key: "email",
            title: "Email",
            align: "left"
        },
        {
            field: "balance",
            key: "balance",
            title: "Balance",
            align: "left"
        },
        {
            field: "createDate",
            key: "createDate",
            title: "Create Date",
            align: "left",
            sortBy: "asc"
        },
        {
            field: "id",
            key: "id",
            title: "ID",
            align: "left"
        }];
    },
    getAllUsers() {
        this.loading = true;
        this.CallHub({task: 'GetUsers', callback: 'usersReceived'});
    },
    fillUpData (e) {
        this.DB_DATA.push({
            username: e.nickname,
            email: e.email,
            balance: e.balance,
            createDate: this.GetFormattedDatetime(e.createDate), 
            id: e.id, 
        });
    },
    pageNumberChange(pageIndex) {
        this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
        this.pageIndex = 1;
        this.pageSize = pageSize;
    },
    openDialog (id, title) {
        this.$root.$emit('openUserDialog', id, title);
    },
    click (obj) {
        if (this.userClickEvent == null) {
            this.openDialog(obj.row.id, obj.row.email);
            window.console.log(obj);
        } else {
            this.userClickEvent(obj);
        }
    },
    fillUpDbData(users) {
        this.DB_DATA = [];
        users.forEach((e) => {
            this.fillUpData(e);
        });
    },
    usersReceived (response) {
        this.users = JSON.parse(response.data);
        this.fillUpDbData(this.users);
        this.loading = false;
    },
    search() {
        const searchValue = this.searchValue;
        const filtered = this.users.filter(
            (x) =>
                !searchValue.length ||
                x.nickname.toLowerCase().includes(searchValue.toLowerCase()) ||
                x.email.toLowerCase().includes(searchValue.toLowerCase()) ||
                x.id.toLowerCase().includes(searchValue.toLowerCase())
        );
        this.fillUpDbData(filtered);
        this.pageNumberChange(1);
    },
    searchReset(closeFn) {
        this.fillUpDbData(this.users);
        closeFn();
    },
    searchConfirm(closeFn) {
        this.search();
        closeFn();
    },
    sortChange(params) {
        this.DB_DATA.sort((a, b) => {
            if (params.createDate) {
                if (params.createDate === "asc") {
                    return Date(b.createDate) < Date(a.createDate) ? 1 : -1;
                } else if (params.createDate === "desc") {
                    return Date(a.createDate) <  Date(b.createDate) ? 1 : -1;
                } else {
                    return 0;
                }
            } 
        });
    }
  }
}
</script>
<style lang="scss">
    .custom-name-filter {
        padding: 10px;

        .custom-name-filter-operation {
            cursor: pointer;

            margin: 10px 10px 0px 0;
            .name-filter-cancel,
            .name-filter-confirm {
                &:hover {
                    color: #1890ff;
                }
            }

            .name-filter-cancel {
                float: left;
            }

            .name-filter-confirm {
                float: right;
            }
        }
    }
</style>