<template>
    <section class="bankroll-analysis">
        <div class="row">
            <div v-if="loading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
                <user-table :userClickEvent="selectUser" />
            </div>
        </div>
        <div v-if="selectedUser == null">
            <div class="pixel-loader"></div>
        </div>
        <div v-else >
            <div class="row">
                <div class="d-flex">
                    {{ selectedUser != null ? selectedUser.row.email : '' }}
                </div>
            </div>
            <div class="row">
                <div class="d-flex">
                    <b-form-group :label="$t('startDate')" label-for="startDate">
                        <datepicker name="startDate" input-class="form-control input-bg-black" :placeholder="$t('startDate')" :open-date="startDate" minimum-view="day" :format="customFormatter" v-model="startDate" :disabled-dates="disabledDates" />
                    </b-form-group>
                    <b-form-group :label="$t('endDate')" label-for="endDate">
                        <datepicker name="endDate" input-class="form-control input-bg-black" :placeholder="$t('endDate')" :open-date="endDate" minimum-view="day" :format="customFormatter" v-model="endDate" :disabled-dates="disabledDates" />
                    </b-form-group>
                </div>
                <div class="d-flex">
                    <b-form-group label="Initial Balance" label-for="initBalance">
                        <b-form-input v-model="initBalance" type="text" name="initBalance" id="initBalance" placeholder="Initial Balance" autocomplete="off" />
                    </b-form-group>
                    <b-form-group label="Bet Amount" label-for="betAmount">
                        <b-form-input v-model="betAmount" type="text" name="betAmount" id="betAmount" placeholder="Bet Amount" autocomplete="off" />
                    </b-form-group>
                </div>
                <b-button id="request-raw-data" class="btn-sm text-uppercase" variant="success" block @click.prevent="clickSimulation" :disabled="disableButton" >Simulate</b-button>
                <b-button id="request-raw-data" class="btn-sm text-uppercase" variant="success" block @click.prevent="clickSimulation2" :disabled="disableButton" >Best Simulate</b-button>
            </div>
    <!--        <div class="row">
                <lineChart :datacollection="weekDataCollection" :options="options" />
                <barChart :datacollection="datacollection" :options="options" />
            </div>-->
            <div v-if="simulationLoading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="d-block">
                        <span>Profit: {{simulationProfit.toFixed(2)}} ({{simulationTotalBet.toFixed(2)}} in total bet)</span><br />
                        <span>ROI: {{simulationRoi.toFixed(2)}}%</span><br />
                        <span>Win Rate: {{simulationWinRate.toFixed(2)}}%</span><br />
                        <span>Total bets: {{simulationTotal.toFixed(2)}}</span><br />
                        <span>Target Odds: {{ simulationOdds.toFixed(2) }}</span><br />
                        <span>Target Weight: {{ simulationWeight.toFixed(2) }}</span><br />
                        <span>Target Increase Rate: {{ simulationRate.toFixed(2) }}</span><br />
                    </div>
                </div>
                <div class="row">
                    <custom-table :columns="simulateColumns" :rows="simulationRows" :title="title" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CustomTable from './custom-table.vue';
//import lineChart from '@/components/charts/chartjs/lineChart.vue'
//import barChart from '@/components/charts/chartjs/barChart.vue'
import userTable from "@/pages/share/user-table"
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'bankroll-analysis',
  components: {
    CustomTable,
//    lineChart,
//    barChart,
    Datepicker,
    userTable
  },
  data() {
    return {
        title: '',
        columns: ['odds range', 'actual percetage', 'profit', 'roi'],
        rows: [],
        loading: false,
        simulationLoading: false,
        disableButton: false,
        initBalance: 100,
        betAmount: 10,
        simulationProfit: 0,
        simulationRoi: 0,
        simulationWinRate: 0,
        simulationTotal: 0,
        simulationTotalBet: 0,
        simulationRows: [],
        simulationOdds: 0,
        simulationWeight: 0,
        simulationRate: 0,
        simulateColumns: ['event date', 'odds', 'bet amount', 'won/lost', 'profit', 'balance'],
        selectedUser: null,
        openDate: new Date(),
        disabledDates: {
            to: new Date('2022-11-01'),
        },
//        startDate: this.getNextWeekDay(new Date('2022-12-01'), 1),
        startDate: new Date('2023-03-01'),
        endDate: this.addDays(this.getNextWeekDay(new Date(), 1), 6),
        betEvents: [],
        datacollection: {},
        weekDataCollection: {},
        options: {},
    };
  },
  created () {
    this.$bobwinHub.$on('getUserBetsReceived', this.getUserBetsReceived);
  },
  beforeDestroy () {
    this.$bobwinHub.$off('getUserBetsReceived', this.getUserBetsReceived);
  },
  methods: {
    init() {
        this.simulationRows = [];
        this.simulationTotal = 0;
        this.simulationTotalBet = 0;
        this.simulationProfit = 0;
        this.simulationWinRate = 0;
        this.simulationRoi = 0;
        this.simulationWeight = 0;
        this.simulationOdds = 0;
        this.simulationRate = 0;
        this.simulationLoading = false;
    },
    getUserBets() {
        this.disableButton = true;
        this.CallHub({task: 'GetUserBets', callback: 'getUserBetsReceived', data: this.selectedUser.row.id});
    },
    getWeekNumber(date) {
        const formattedDate = this.$moment(date, 'YYYY-MM-DD');
        const weekNumber = formattedDate.isoWeek();
        return weekNumber;
    },
    convertToEST(dateTimeString) {
        const date = new Date(dateTimeString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date-time string');
        }

        // Create an Intl.DateTimeFormat object with the desired format
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: 'America/New_York',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });

        const parts = formatter.formatToParts(date);
        const estDateTime = `${parts[4].value}-${parts[0].value}-${parts[2].value} ${parts[6].value}:${parts[8].value}:${parts[10].value}`;

        return estDateTime;
    },
    getSimulatedList(startDate, endDate) {
        const simulatedList = [];
        const data = this.rows;
        data.sort((a, b) => {
            const dateA = new Date(a.event.startDate);
            const dateB = new Date(b.event.startDate);

            return dateA.getTime() - dateB.getTime();
        });
        data.forEach((e) => {
            if (e.status == 1 || e.status == 2) {
                if ((e.event.startDate > startDate) && (e.event.startDate < endDate)) {
                    simulatedList.push({ startDate: this.convertToEST(e.event.startDate), odds: e.odds, isWon: e.status == 1});
                }
            }
        });
        return simulatedList;
    },
    getUserBetsReceived(response) {
        const data = JSON.parse(response.data);
        this.rows = data;
        this.disableButton = false;
    },
    getUnitSize(unitSize, weight, odds, targetOdds) {
        if (weight == 0) {
            return unitSize;
        }
        const appliedWeight =  (odds >= targetOdds) ? (1.0 + Number(weight)) : (1.0 - Number(weight))
        return (unitSize * appliedWeight).toFixed(2);
    },
    asyncFunction(func) {
        return new Promise((resolve, reject) => {
            const result = func();
            setTimeout(() => {
                if (!result) {
                    reject(new Error('Return false'));
                } else {
                    resolve();
                }
            }, 500);
        });
    },
    clickSimulation() {
        this.asyncFunction(() => { this.simulationLoading = true; return true; }).then(() => {
            this.asyncFunction(this.runSimulation).then(() => {
                this.simulationLoading = false; 
            });
        });
    },
    clickSimulation2() {
        this.asyncFunction(() => { this.simulationLoading = true; return true; }).then(() => {
            this.asyncFunction(this.runBestSimulation).then(() => {
                this.simulationLoading = false; 
            });
        });
    },
    getWeeks(startDate, endDate) {
        let start = new Date(startDate);
        let end = new Date(endDate);
        let result = [];

        while (start.getDay() !== 1) {
            start.setDate(start.getDate() - 1);
        }

        while (start <= end) {
            let weekEnd = new Date(start);
            weekEnd.setDate(weekEnd.getDate() + 6);

            if (weekEnd > end) {
                weekEnd = end;
            }

            result.push(weekEnd);
            start.setDate(start.getDate() + 7);
        }

        return result;
    },
    runBestSimulation() {
        const orignalBalance = Number(this.initBalance);
        const startDate = this.utcFormatDate000(this.startDate);
        const endDate = this.utcFormatDate000(this.endDate);
        let unitSize = this.betAmount;
        let bests = this.getBestBankrollByWeek(orignalBalance, startDate, endDate, unitSize);
        const weeks = this.getWeeks(new Date(startDate), new Date(endDate));
        let balance = orignalBalance;
        let start = new Date(startDate);
        let total = null;
        for (let i = 0; i < weeks.length; ++i) {
            let end = weeks[i];
            end.setDate(weeks[i].getDate() + 1);
            const best = i < 1 ? {simulationWeight: 0, simulationOdds: 0, simulationRate: 0 } : bests[i - 1];
            const simulation = this.doSimulation(balance, this.utcFormatDate(start), this.utcFormatDate(end), best.simulationWeight, unitSize, best.simulationOdds, best.simulationRate);
            balance = simulation.balance;
            //unitSize = unitSize * (best.simulationRate <= 0 ? 1 : best.simulationRate);
            start = end;
            if (total == null) {
                total = simulation;
            } else {
                total.rows = total.rows.concat(simulation.rows);
                total.total += simulation.total;
                total.totalBet += simulation.totalBet;
                total.profit += simulation.profit;
                total.balance = simulation.balance;
            }
        }
        this.simulationRows = total.rows;
        this.simulationTotal = total.total;
        this.simulationTotalBet = total.totalBet;
        this.simulationProfit = total.profit;
        this.simulationWinRate = total.rate;
        this.simulationRoi = total.profit / orignalBalance * 100;
        this.simulationLoading = false;
        return true;
    },
    getBestBankrollByWeek(orignalBalance, startDate, endDate, betAmount) {
        const result = [];
        const weeks = this.getWeeks(new Date(startDate), new Date(endDate));
        for (let i = 0; i < weeks.length; ++i) {
            const week = weeks[i];
            result.push(this.getBestBankroll(orignalBalance, startDate, this.utcFormatDate(week), betAmount));
        }
        return result;
    },
    getBestBankroll(orignalBalance, startDate, endDate, betAmount) {
        const weights = [0.9, -0.9];
        const odds = [];
        for (let i = 1.1; i < 3.0; i = i + 0.1) {
            odds.push(i);
        }
        const increase = [];
        for (let i = 1.1; i < 1.5; i = i + 0.1) {
            increase.push(i);
        }
        let best = null;
        for (let i = 0; i < weights.length; ++i) {
            for (let j = 0; j < odds.length; ++j) {
                for (let n = 0; n < increase.length; ++n) {
                    const sim = this.doSimulation(orignalBalance, startDate, endDate, weights[i], betAmount, odds[j], increase[n]);
                    if (best == null || sim.profit > best.profit) {
                        best = sim;
                        best.simulationWeight = weights[i];
                        best.simulationOdds = odds[j];
                        best.simulationRate = increase[n];
                    }
                }
            }
        }
        return best;
    },
    runSimulation() {
        const orignalBalance = Number(this.initBalance);
        const startDate = this.utcFormatDate000(this.startDate);
        const endDate = this.utcFormatDate000(this.endDate);
        const betAmount = this.betAmount;

        const best = this.getBestBankroll(orignalBalance, startDate, endDate, betAmount);
        
        this.simulationWeight = best.simulationWeight;
        this.simulationOdds = best.simulationOdds;
        this.simulationRate = best.simulationRate;
        this.simulationRows = best.rows;
        this.simulationTotal = best.total;
        this.simulationTotalBet = best.totalBet;
        this.simulationProfit = best.profit;
        this.simulationWinRate = best.rate;
        this.simulationRoi = best.roi;
        this.simulationLoading = false;
        return true;
    },
    doSimulation(orignalBalance, startDate, endDate, param_weight, param_betAmount, param_targetOdds, param_increaseRate) {
        let simulationBalance = orignalBalance;
        let simulationRows = [];
        let dic = {};
        let simulationTotal = 0;
        let simulationBetTotal = 0;
        let simulationProfit = 0;
        let won = 0;
        const weight = Number(param_weight);
        let inputUnitSize = param_betAmount;
        const unitRate = param_betAmount/orignalBalance;
        const targetOdds = Number(param_targetOdds);
        const simulatedList = this.getSimulatedList(startDate, endDate);
        const targetBalanceRate = 1.5;
        let targetBalance = orignalBalance * targetBalanceRate;
        simulatedList.forEach((e) => {
            if (simulationBalance < 0)
                return;
            const key = this.GetFormattedDate(new Date(e.startDate));
            const existingValue = dic[key] ? dic[key] : 0;
            if (existingValue == 0 && targetBalance < simulationBalance) {
                targetBalance *= targetBalanceRate;
                inputUnitSize = Number((simulationBalance * unitRate * param_increaseRate).toFixed(0));
            }
            const unitSize = Number(this.getUnitSize(inputUnitSize, weight, e.odds, targetOdds));
            simulationBetTotal += unitSize;
            const profit = e.isWon ? (e.odds - 1) * unitSize : -unitSize;
            simulationBalance += profit;
            dic[key] = existingValue + profit;
            simulationProfit += profit;
            simulationTotal++;
            if (e.isWon) {
                won++;
            }
            simulationRows.push([e.startDate, e.odds, unitSize.toFixed(2), e.isWon ? 'Won' : 'Lost', this.GetFloorValue(profit, 2), simulationBalance.toFixed(2)]);
        });
        const result = { 
            rows: simulationRows,
            total: simulationTotal,
            totalBet: simulationBetTotal,
            profit: simulationProfit,
            rate: simulationTotal == 0 ? 0 : won / simulationTotal * 100,
            roi: simulationTotal == 0 ? 0 : simulationProfit / (orignalBalance) * 100,
            balance: simulationBalance
        };
        return result;
    },
    getEndOfDate(datetime) {
        return this.removeTimeFromDateUTC(this.addDays(datetime, 1));
    },
    getNextWeekDay(date, d) {
        if (d) {
            var next = date;
            next.setDate(date.getDate() - date.getDay() + 7 + d);
            return next;
        }
    },
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    customFormatter(date) {
      return this.$moment(date).format('YYYYMMDD');
    },
    utcFormatDate000(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}T00:00:00`;
    },
    utcFormatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
    removeTimeFromDateUTC(datetime) {
        let date = new Date(datetime);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    },
    getDataCollection(keys, values) {
      const borderColors = [];
      const color = 'rgba(83,245,89,1)';
      for(let i = 0; i < values.length; ++i) {
        borderColors.push(color);
      }
      return {
        labels: keys,
        datasets: [{
          label: 'Profit',
          data: values,
          borderColor: borderColors,
          borderWidth: 2,
          fill: false
        }]};
    },
    getOptions() {
      return {
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }],
          xAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }]
        },
        legend: { display: true },
        elements: { point: { radius: 0 } },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    selectUser(obj) {
        this.selectedUser = obj;
        this.init();
        this.getUserBets();
    }
  }
}
</script>
<style lang="scss">
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>