<template>
    <section class="event-table">
        <div v-if="loading">
            <div class="pixel-loader"></div>
        </div>
        <div v-else>
            <ve-table v-if="!loading"
                id="event-grid" 
                :columns="columns" 
                :table-data="tableData"
                row-key-field-name="id"
                :checkbox-option="checkboxOption"
                :fixed-header="true"
                :max-height="600"
            />
            <div class="table-pagination bg-white pt-3">
                <ve-pagination
                    :total="totalCount"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    @on-page-number-change="pageNumberChange"
                    @on-page-size-change="pageSizeChange"
                />
            </div>
        </div>
    </section>
</template>

<script>
import "vue-easytable/libs/theme-default/index.css";
import { VeTable, VePagination } from "vue-easytable"; 

export default {
  name: 'event-table',
  components: {
    VeTable,
    VePagination
  },
  data() {
    return {
        loading: true,
        checkboxOption: {
            selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
                window.console.log(row, isSelected, selectedRowKeys);
                this.$emit('selected-events', selectedRowKeys);
            },
            selectedAllChange: ({ isSelected, selectedRowKeys }) => {
                window.console.log(isSelected, selectedRowKeys);
            },
        },
        pageIndex: 1,
        pageSize: 10,
        columns: [],
        DB_DATA: [],
        searchData: {
            category: [],
            sub: [],
        }
    };
  },
  props: ["events"],
  computed: {
    tableData() {
        const { pageIndex, pageSize } = this;
        return this.DB_DATA.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
    },
    totalCount() {
        return this.DB_DATA.length;
    },
  },
  created () {
    this.init();
  },
  beforeDestroy () {
  },
  watch: {
    searchData: {
        handler: function () {
            this.search();
        },
        immediate: true,
        deep: true,
    },
    events: {
        handler: function () {
            this.eventsReceived();
        },
        immediate: true,
        deep: true,
    }
  },
  methods: {
    init () {
      this.loading = false;
    },
    search() {
        const { category, sub } = this.searchData;
        const filtered = this.events.filter((x) => 
            (category.length === 0 || category.includes(x.eventCategoryName)) 
            && (sub.length === 0 || sub.includes(x.subcategory.name))
        );
        this.DB_DATA = [];
        filtered.forEach((e) => {
            this.fillUpData(e);
        });
    },
    fillUpData (e) {
        this.DB_DATA.push({
            sport: e.eventCategoryName,
            league: e.subcategory.name,
            title: e.title, 
            start: this.GetFormattedDatetime(e.startDate),
            bet: e.hasBet ? 'yes':'no',
            id: e.id, 
        });
    },
    pageNumberChange(pageIndex) {
        this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
        this.pageIndex = 1;
        this.pageSize = pageSize;
    },
    eventsReceived () {
      const categoreis = [];
      const sub = [];
      this.DB_DATA = [];
      this.events.forEach((e) => {
        this.fillUpData(e);
        if (!categoreis.includes(e.eventCategoryName)) categoreis.push(e.eventCategoryName);
        if (!sub.includes(e.subcategory.name)) sub.push(e.subcategory.name);
      });
      this.columns = [
        {
            field: "",
            key: "a",
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "sport",
            key: "sport",
            title: "Sport",
            align: "left",
            filter: {
                filterList: categoreis.map((val, idx) => { return { value: idx, label: val, selected: false }}),
                isMultiple: true,
                filterConfirm: (filterList) => {
                    const labels = filterList
                        .filter((x) => x.selected)
                        .map((x) => x.label);
                    this.searchData.category = labels;
                },
                filterReset: (filterList) => {
                    window.console.log(filterList);
                    this.searchData.category = [];
                },
                maxHeight: 120,
            },
        },
        {
            field: "league",
            key: "league",
            title: "League",
            align: "left",
            filter: {
                filterList: sub.map((val, idx) => { return { value: idx, label: val, selected: false }}),
                isMultiple: true,
                filterConfirm: (filterList) => {
                    const labels = filterList
                        .filter((x) => x.selected)
                        .map((x) => x.label);
                    this.searchData.sub = labels;
                },
                filterReset: (filterList) => {
                    window.console.log(filterList);
                    this.searchData.sub = [];
                },
                // max height
                maxHeight: 120,
            },
        },
        { field: "title", key: "title", title: "Title", align: "left" },
        { field: "start", key: "start", title: "Start Date", align: "right" },
        { field: "bet", key: "bet", title: "Bet", align: "center" },
        { field: "id", key: "id", title: "Id", align: "center" }];
      this.loading = false;
    },
  }
}
</script>
