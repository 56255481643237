<template>
    <section class="laderboard">
        <div >
            <b-form-group :label="$t('startDate')" label-for="startDate">
                <datepicker name="startDate" input-class="form-control input-bg-black" :placeholder="$t('startDate')" :open-date="startDate" minimum-view="day" :format="customFormatter" v-model="startDate" :disabled-dates="disabledDates" />
                <b-button id="get-ranking" class="btn-sm text-uppercase" variant="success" block @click.prevent="getWeeklyRanking" :disabled="disableButton" >Get Ranking</b-button>
            </b-form-group>
        </div>
        <div >
          <span class="card-title h3">{{$t('ranking')}}</span>
            <div v-if="loading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
              <bet-rank v-if="!!endOfWeek" :rankList="weekRankings" :deadline="endOfWeek" :title="GetFormattedDate(RangeOfWeek['start']) + '~' + GetFormattedDate(RangeOfWeek['end']) + ' ' "/>
            </div>
        </div>
      <UserDetail />
    </section>
  </template>
  
  <script lang="js">
  import UserDetail from "@/pages/dialogs/userDetail";
  import betRank from '@/pages/share/bet-rank';
  import Datepicker from 'vuejs-datepicker'
  
  export default {
    name: 'weekly-ranking',
    components: {
      UserDetail,
      betRank,
      Datepicker
    },
    data() {
      return {
        loading: true,
        disableButton: false,
        weekRankings: [],
        endOfWeek: '',
        startDate: this.getUtcNow(),
        disabledDates: {
            to: new Date('2022-11-01'),
        },
        RangeOfWeek: {}
      };
    },
    created () {
      this.$bobwinHub.$on('onLeaderBoardReceived', this.onLeaderBoardReceived);
  
      this.loading = false;
    },
    beforeDestroy () {
      this.$bobwinHub.$off('onLeaderBoardReceived', this.onLeaderBoardReceived);
    },
    methods: {
      init () {
        if (Array.isArray(this.weekRankings)) {
          const monday = this.getMondayDate(this.startDate);
          const sunday = this.addDaysToDate(monday, 7);
          this.endOfWeek = sunday;
          this.RangeOfWeek = { start: monday, end: sunday };
          this.loading = false;
          this.disableButton = false;
        }
      },
      getUtcNow() {
        const date = new Date();
        const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                  date.getUTCDate(), date.getUTCHours(),
                  date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(now_utc);
      },
      getUtcDate(startDate) {
        const now_utc = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(),
          startDate.getUTCDate(), 0, 0, 0);
        return new Date(now_utc);
      },
      getMondayDate (date) {
        const today = date;
        const dayOfWeek = today.getUTCDay();
        const diff = today.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        const utcMonday = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), diff, 0, 0, 0);
        const monday = new Date(utcMonday);
        return monday;
      },
      addDaysToDate(date, days) {
        const result = new Date(date);
        result.setUTCDate(result.getUTCDate() + days);
        return result;
      },
      getWeeklyRanking() {
        this.loading = true;
        this.disableButton = true;
        const yearAndWeek = this.getYearAndWeek(this.getUtcDate(this.startDate));
        this.CallHub({task: 'GetWeeklyRanking', callback: 'onLeaderBoardReceived', data: JSON.stringify({ Year:yearAndWeek.year, Week:yearAndWeek.week })});
      },
      onLeaderBoardReceived (response) {
        const dashboardData = JSON.parse(response.data);
        this.weekRankings = dashboardData.weekRankings.sort(function (a,b) {
            return a.amount > b.amount ? -1 : 1;
          });
        this.init();
      },
      getNextWeekDay(date, d) {
        if (d) {
            var next = date;
            next.setDate(date.getDate() - date.getDay() + 7 + d);
            return next;
        }
      },
      customFormatter(date) {
        return this.$moment(date).format('YYYYMMDD');
      },
      getYearAndWeek(date) {
        const year = date.getFullYear();
        const januaryFirst = new Date(year, 0, 1);
        const dayOfjanuaryFirst = (januaryFirst.getDay() + 6) % 7; // offset to Monday of the first week
        const daysOffset = 6 - dayOfjanuaryFirst + 1;
        const firstMondayOfYear = new Date(year, 0, 1 + (daysOffset || 7)); // if daysOffset is 0, we need to go to next week
        const pastDaysOfYear = (date - firstMondayOfYear) / 86400000;
        const week = Math.floor(pastDaysOfYear / 7) + 1;
        return { year, week };
      }
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  .overflow-height {
    height: 500px;
  }
  .sortable tr {
    cursor: pointer;
  }
  
  </style>