<template>
  <section class="6enter">
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <b-tabs content-class="mt-3" fill>
        <b-tab active>
          <template slot="title">
            <i class='mdi mdi-google-analytics'></i> Strategic bankroll
          </template>
          <div >
            <div class="col-md-12">
                <strategic-bankroll />
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template slot="title">
            <i class='mdi mdi-google-analytics'></i> Martingale bankroll
          </template>
          <div >
            <div class="col-md-12">
                <bankroll-strategy />
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template slot="title">
            <i class='mdi mdi-google-analytics'></i> Odds Analysis
          </template>
          <div >
            <div class="col-md-12">
                <odds-analysis />
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template slot="title">
            <i class='mdi mdi-google-analytics'></i> Bankroll Analysis
          </template>
          <div >
            <div class="col-md-12">
                <bankroll-analysis />
            </div>
          </div>
        </b-tab>
        <b-tab >
          <template slot="title">
            <i class='mdi mdi-google-analytics'></i> Weekly Ranking
          </template>
          <div >
            <div class="col-md-12">
                <weekly-ranking />
            </div>
          </div>
        </b-tab>
        <b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi-account'></i> Users
          </template>
          <div >
            <div class="col-md-12">
                <user-table />
            </div>
          </div>
        </b-tab>
        <b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi-cancel'></i> Cancel Event
          </template>
          <div >
            <div class="col-md-12">
                <cancel-event />
            </div>
          </div>
        </b-tab>
        <b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi-cancel'></i> Vote Event
          </template>
          <div >
            <div class="col-md-12">
              <router-link class="nav-link text-muted" to="/vote">{{ $t('vote') }}</router-link>
            </div>
          </div>
        </b-tab>
        <!--<b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi-cancel'></i> Cancel Bet
          </template>
          <div >
            <div class="col-md-12">
                <cancel-bet />
            </div>
          </div>
        </b-tab>-->
        <b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi-home-outline'></i> Survival
          </template>
          <div >
            <div class="col-md-12">
                <survival-table />
            </div>
          </div>
        </b-tab>
        <b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi-new-box'></i> Create Survival
          </template>
          <div >
            <div class="col-md-12">
                <create-survival />
            </div>
          </div>
        </b-tab>

        <b-tab v-if="developmentDone">
          <template slot="title">
            <i class='mdi mdi mdi-email'></i> Send Email
          </template>
          <div >
            <div class="col-md-12">
                <send-email />
            </div>
          </div>
        </b-tab>

      </b-tabs>
    </div>
  </section>
</template>

<script>
import survivalTable from "@/pages/share/survival-table"
import userTable from "@/pages/share/user-table"
import createSurvival from "@/pages/share/create-survival"
import cancelEvent from "@/pages/share/cancel-event"
//import cancelBet from "@/pages/share/cancel-bet"
import oddsAnalysis from "@/pages/share/odds-analysis"
import weeklyRanking from "@/pages/share/weekly-ranking"
import bankrollAnalysis from "@/pages/share/bankroll-analysis"
import bankrollStrategy from "@/pages/share/bankroll-strategy"
import strategicBankroll from "@/pages/share/strategic-bankroll"
import sendEmail from '@/pages/admin/send-email'

export default {
  name: 'hidden-admin',
  components: {
    survivalTable,
    userTable,
    createSurvival,
    cancelEvent,
    //cancelBet,
    oddsAnalysis,
    bankrollAnalysis,
    weeklyRanking,
    bankrollStrategy,
    strategicBankroll,
    sendEmail
  },
  data() {
    return {
        loading: false,
        developmentDone: true
    };
  },
  computed: {
    
  },
  created () {
    
  },
  beforeDestroy () {
  },
  methods: {
  }
}
</script>


<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}

</style>