<template>
  <section class="user-dashboard">
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{{ GetFloorValue(user.balance, 2) }}</h3>
                    <p class="text-success ml-2 mb-0 font-weight-medium">Play money</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon icon-box-success ">
                    <span class="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">{{$t('currentBalance')}}</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{{ GetFloorValue(profit, 2) }}</h3>
                    <p class="ml-2 mb-0 font-weight-medium" :class="profit >= 0 ? 'text-success' : 'text-danger'">Play money</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon" :class="profit < 0 ? 'icon-box-danger' : 'icon-box-success'">
                    <span class="mdi icon-item" :class="profit < 0 ? 'mdi-arrow-bottom-left' : 'mdi-arrow-top-right'"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">{{$t('profitLoss')}}</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{{ roi }}</h3>
                    <p class="ml-2 mb-0 font-weight-medium" :class="roi < 0 ? 'text-danger' : 'text-success'">%</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon" :class="roi < 0 ? 'icon-box-danger' : 'icon-box-success'">
                    <span class="mdi icon-item" :class="roi < 0 ? 'mdi-arrow-bottom-left' : 'mdi-arrow-top-right'"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">{{$t('roi')}}</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{{ winRate }}</h3>
                    <p class="ml-2 mb-0 font-weight-medium" :class="winRate < 0 ? 'text-danger' : 'text-success'">%</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon" :class="winRate < 0 ? 'icon-box-danger' : 'icon-box-success'">
                    <span class="mdi icon-item" :class="winRate < 0 ? 'mdi-arrow-bottom-left' : 'mdi-arrow-top-right'"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">{{$t('winrate')}}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{$t('bettingAnalysis')}}</h4>
              <div class="aligner-wrapper">
                <transactionHistoryChart :height='200' :labels=transactionLabels :percentages=transactionPercetages></transactionHistoryChart>
                <div class="wrapper d-flex flex-column justify-content-center absolute absolute-center">
                  <h6 class="text-center mb-0 mt-3">{{ totalSpent.toFixed(0) }}</h6>
                  <p class="text-center text-small text-muted mt-2">{{$t('totalSpent')}}</p>
                </div>
              </div>
              <div v-for="item in analysis" :key="item.id" class="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                <div class="text-md-center text-xl-left">
                  <h6 class="mb-1">{{item.label}}</h6>
                  <p class="font-weight-bold text-muted mb-0">{{item.data}}</p>
                </div>
                <div class="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                  <!--<h6 class="mb-0">{{(new Date(item.betDate)).toLocaleDateString()}}</h6>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row justify-content-between">
                <h4 class="card-title mb-1">{{$t('bettingInprogress')}}</h4>
                <p class="text-muted mb-1">{{$t('eventDate') + " & " + $t('betAmount') + " (" + $t('odds') + ")"}}</p>
              </div>
              <perfect-scrollbar>
                <div class="row">
                  <div class="col-12  overflow-height">
                    <div class="preview-list">
                      <div v-for="item in openBets" :key="item.id" class="preview-item border-bottom py-3">
                        <div class="preview-thumbnail">
                          <div class="preview-icon bg-primary">
                            <i class="mdi mdi-file-document"></i>
                          </div>
                        </div>
                        <div class="preview-item-content d-sm-flex flex-grow">
                          <div class="flex-grow">
                            <h6 class="preview-subject">{{item.eventTitle}}</h6>
                            <p class="text-muted mb-0">{{item.gameOptionTitle}}</p>
                          </div>
                          <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p class="text-muted">{{GetFormattedDatetime(item.eventDate)}}</p>
                            <p class="text-muted mb-0">{{ item.amount }} (<odds-value :odds="item.odds" />)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <bet-history :bets="sortedBets"/>
      </div>
    </div>
    <UserDetail />
  </section>
</template>

<script>
import transactionHistoryChart from '@/components/charts/widgets/transactionHistoryChart'
import UserDetail from "@/pages/dialogs/userDetail";
import oddsValue from '@/pages/share/odds-value';
import betHistory from '@/pages/share/bet-history';

export default {
  name: 'user-dashboard',
  components: {
    transactionHistoryChart,
    UserDetail,
    oddsValue,
    betHistory
  },
  data() {
    return {
      loading: true,
      profit: 0,
      roi: '',
      winRate: '',
      totalSpent: 0,
      transactionLabels: [this.$t('status.open'), this.$t('status.won'), this.$t('status.lost')],
      transactionPercetages: [100, 0, 0],
      analysis: [],
      bets: [],
      user: {}
    };
  },
  props: {
    userGuid: String
  },
  computed: {
    openBets() {
      if (Array.isArray(this.bets)) {
        let copy = [...this.bets.filter(bet => bet.status === 0)];
        copy.sort(function (a,b) {
          return a.eventDate > b.eventDate ? 1 : -1;
        });
        return copy;
      }
      return [];
    },
    sortedBets() {
      if (Array.isArray(this.bets)) {
        let copy = [...this.bets];
        copy.sort(function (a,b) {
          return a.eventDate > b.eventDate ? -1 : 1;
        });

        return copy;
      }
      return [];
    },
  },
  created () {
    this.$bobwinHub.$on('myDashboardReceived', this.onMyDashboardReceived);
  },
  beforeDestroy () {
    this.$bobwinHub.$off('myDashboardReceived');
  },
  watch: {
    'userGuid': {
        handler(newValue, oldValue){
            this.loading = true;
            this.getDashboard(newValue);
            window.console.log(oldValue);
        },
        immediate: true
    }
  },
  methods: {
    init () {
      if (Array.isArray(this.bets)) {
        const wonBets = this.bets.filter(bet => bet.status === 1);
        const lostBets = this.bets.filter(bet => bet.status === 2);

        let roiTotal = 0;
        let won = 0;
        wonBets.forEach(bet => {
          won += ((bet.odds * bet.amount) - bet.amount);
          roiTotal += bet.amount;
        });
        let lost = 0;
        lostBets.forEach(bet => {
          lost -= bet.amount;
          roiTotal += bet.amount;
        });
        this.profit = won + lost;
        this.bets.forEach(bet => {
          this.totalSpent += bet.amount;
        });
        this.roi = roiTotal === 0 ? 'N/A' : (this.profit / roiTotal * 100).toFixed(2);
        this.winRate = (wonBets.length + lostBets.length) === 0 ? 'N/A' : (wonBets.length / (wonBets.length + lostBets.length) * 100).toFixed(0);

        let copy = [...this.bets];
        copy.sort(function (a,b) {
          return a.betDate > b.betDate ? 1 : -1;
        });

        const numberOfWon = wonBets.length;
        const numberOfLost = lostBets.length;
        const numberOfOpen = this.bets.length - numberOfWon - numberOfLost;
        this.transactionPercetages = [(numberOfOpen / this.bets.length) * 100, 
          (numberOfWon / this.bets.length) * 100, 
          (numberOfLost / this.bets.length) * 100];

        this.analysis = [ 
          { label: this.$t('numberOfWon'), data: numberOfWon}, 
          { label: this.$t('numberOfLost'), data: numberOfLost}, 
          { label: this.$t('numberOfOpen'), data: numberOfOpen},
          { label: this.$t('totalSpent') + '(playmoney)', data: this.totalSpent.toFixed(0)}
        ];
        this.loading = false;
      }
    },
    getDashboard (userId) {
      this.CallHub({task: 'GetMyDashboard', callback: 'myDashboardReceived', data: userId});
    },
    onMyDashboardReceived (response) {
      const dashboardData = JSON.parse(response.data);
      this.bets = dashboardData.bets;
      this.user = dashboardData.user;
      this.init();
    },
  }
}
</script>


<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>