<template>
    <section class="cancel-event">
        <div class="d-lg-flex">
        </div>
        <div>
            <div v-if="loading">
                <div class="pixel-loader"></div>
            </div>
            <div v-else>
                <b-button id="cancel-event" class="btn-sm text-uppercase" variant="success" block @click.prevent="cancelEvent" :disabled="disableButton" >{{ $t('cancel') }}</b-button>
                <event-table :events="events" @selected-events="onEventsSelected" />
            </div>
        </div>
</section>
</template>

<script>
import eventTable from "@/pages/share/event-table"

export default {
  name: 'cancel-event',
  components: {
    eventTable,
  },
  data() {
    return {
        loading: false,
        disableButton: false,
        eventIds: [],
        events: []
    };
  },
  created () {
    this.$bobwinHub.$on('getCancelableEventsReceived', this.getCancelableEventsReceived);
    this.$bobwinHub.$on('cancelEventReceived', this.cancelEventReceived);
    this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('getCancelableEventsReceived', this.getCancelableEventsReceived);
    this.$bobwinHub.$off('cancelEventReceived', this.cancelEventReceived);
  },
  methods: {
    init() {
        this.loading = true;
        this.CallHub({task: 'GetCancelableEvents', callback: 'getCancelableEventsReceived'});
    },
    getCancelableEventsReceived (response) {
        this.events = JSON.parse(response.data);
        this.loading = false;
    },
    onEventsSelected(values) {
        this.eventIds = values;
    },
    cancelEvent() {
        this.disableButton = true;
        const eventsList = this.eventIds.map((e) => { return {Id: e}});
        eventsList.forEach(e => {
            const eventDto = {
                Id: e.Id,
                UserId: this.UserId
            };

            this.CallHub({task: 'CancelEvent', callback: 'cancelEventReceived', data: JSON.stringify(eventDto)});
        });
    },
    cancelEventReceived(response) {
        if (response != null && response.result == 'success') {
            this.ShowSuccessMessage("Canceled");
        } else {
            this.ShowErrorMessage(response?.message);
        }
        this.init();
        this.disableButton = false;
    }
  }
}
</script>
