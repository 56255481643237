<template>
    <section class="strategic-bankroll">
        <div >
            <div class="d-flex">
                <b-form-group :label="$t('startDate')" label-for="startDate">
                    <datepicker name="startDate" input-class="form-control input-bg-black" :placeholder="$t('startDate')" :open-date="startDate" minimum-view="day" :format="customFormatter" v-model="startDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group :label="$t('endDate')" label-for="endDate">
                    <datepicker name="endDate" input-class="form-control input-bg-black" :placeholder="$t('endDate')" :open-date="endDate" minimum-view="day" :format="customFormatter" v-model="endDate" :disabled-dates="disabledDates" />
                </b-form-group>
                <b-form-group label="League" label-for="league">
                    <b-form-input v-model="league" type="text" name="league" id="league" placeholder="League Name" autocomplete="off" />
                </b-form-group>
            </div>
        </div>
        <div >
            <div class="d-flex">
                <b-form-group label="From Odds" label-for="oddsFrom">
                    <b-form-input v-model="oddsFrom" type="text" name="oddsFrom" id="oddsFrom" placeholder="From Odds" autocomplete="off" />
                </b-form-group>
                <b-form-group label="To Odds" label-for="oddsTo">
                    <b-form-input v-model="oddsTo" type="text" name="oddsTo" id="oddsTo" placeholder="To Odds" autocomplete="off" />
                </b-form-group>
                <b-form-group label="Initial Balance" label-for="initBalance">
                    <b-form-input v-model="initBalance" type="text" name="initBalance" id="initBalance" placeholder="Initial Balance" autocomplete="off" />
                </b-form-group>
                <b-form-group label="Unit Size" label-for="initUnitSize">
                    <b-form-input v-model="initUnitSize" type="text" name="initUnitSize" id="initUnitSize" placeholder="Unit Size" autocomplete="off" />
                </b-form-group>
<!--                <b-form-group label="Select Team" label-for="selectTeam">
                    <b-form-input v-model="selectTeam" type="text" name="selectTeam" id="selectTeam" placeholder="Select Team" autocomplete="off" />
                </b-form-group>
                -->
            </div>
            <b-button id="request-raw-data" class="btn-sm text-uppercase" variant="success" block @click.prevent="getRawData" :disabled="disableButton" >Get Data</b-button>
        </div>
        <div >
            <custom-table :columns="simulateColumns" :rows="simulationRows" :title="title" />
        </div>
        <div >
            <b-button id="request-simulation-all" class="btn-sm text-uppercase" variant="success" block @click.prevent="dailyProfit" :disabled="teamsArray.length == 0" >Daily profit</b-button>
        </div>
        <div >
            <b-button id="request-simulation-all" class="btn-sm text-uppercase" variant="success" block @click.prevent="clickSimulateAll" :disabled="teamsArray.length == 0" >Analyze all</b-button>
            <custom-table :columns="teamsDataColumns" :rows="teamsData" :title="title" />
        </div>
        <div >
            <v-select 
                id="event-teams" v-model="selectTeam"  class="form-control"
                :options="teamsArray" :clearable="false"
                :placeholder="$t('selectTeam')">
            </v-select>
            <b-button id="request-simulation" class="btn-sm text-uppercase" variant="success" block @click.prevent="clickSimulate" :disabled="selectTeam == ''" >Simulate</b-button>
            <div >
                <div class="d-block">
                    <span>Win Rate: {{ (win/total*100).toFixed(0) }} % ({{ win }}/{{ total }})</span><br />
                    <span>Underdog rate: {{(totalUnderdog/total*100).toFixed(0)}}% ({{ totalUnderdog }}/{{ total }})</span><br />
                    <span>Upset win rate among underdog: {{(upset/totalUnderdog*100).toFixed(0)}}% ({{ upset }}/{{ totalUnderdog }})</span><br />
                    <span>Max Lost: {{maxLost}}</span><br />
                </div>
            </div>
            <custom-table :columns="logsColumns" :rows="logs" :title="title" />
        </div>
    </section>
</template>

<script>
import CustomTable from './custom-table.vue';
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'strategic-bankroll',
  components: {
    CustomTable,
    Datepicker
  },
  data() {
    return {
        title: '',
        columns: ['odds range', 'win rate(# of win)', 'profit', 'roi'],
        rows: [],
        loading: false,
        runSimulation: false,
        disableButton: false,
        oddsFrom: 1.0,
        oddsTo: 13.0,
        initBalance: 1000,
        selectTeam: '',
        teams: {},
        teamsArray: [],
        simulationProfit: 0,
        simulationRoi: 0,
        simulationWinRate: 0,
        simulationTotal: 0,
        simulationRows: [],
        simulateColumns: ['no', 'date', 'title', 'game', 'winner', 'odds', 'loser', 'odds'],
        league: 'MLB',
        openDate: new Date(),
        disabledDates: {
            to: new Date('2022-11-01'),
        },
        startDate: new Date('2023-04-01'),
        endDate: this.addDays(this.getNextWeekDay(new Date(), 1), 6),
        betEvents: [],
        datacollection: {},
        options: {},
        balance: 1000,
        initUnitSize: 1,
        maxLost: 0,
        maxLostAmount: 0,
        maxWon: 0,
        maxWonAmount: 0,
        total: 0,
        win: 0,
        upset: 0,
        totalUnderdog: 0,
        logs: [],
        logsColumns: ['no', 'status', 'balance', 'date', 'title', 'game', 'winner', 'odds', 'loser', 'odds'],
        teamsDataColumns: ['no', 'team', 'win rate', 'underdog rate', 'upset win rate', 'max lost', 'balance'],
        teamsData: [],
    };
  },
  created () {
    this.$bobwinHub.$on('eventsRawDataReceived', this.eventsRawDataReceived);
    //this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('eventsRawDataReceived', this.eventsRawDataReceived);
  },
  methods: {
    init() {
    },
    getRawData() {
        this.disableButton = true;
        this.CallHub({task: 'GetEventsRawData', callback: 'eventsRawDataReceived', data: JSON.stringify({SportLeague: this.league, StartDate: this.startDate, EndDate: this.endDate})});
    },
    getWeekNumber(date) {
        const formattedDate = this.$moment(date, 'YYYY-MM-DD');
        const weekNumber = formattedDate.isoWeek();
        return weekNumber;
    },
    dailyProfit() {
        const underdog = {};
        const topdog = {};
        let underdogbalance = 10000;
        let topdogbalance = 10000;
        const unit = 1;
        this.betEvents.forEach(e => {
            underdogbalance -= unit;
            let win = 0;
            if (e[4] > e[6]) {
                win = e[4] * unit;
            } 
            const result1 = win - unit;
            underdog[e[0]] = underdog[e[0]] == null ? result1 : (underdog[e[0]] + result1);
            underdogbalance += win;

            topdogbalance -= unit;
            let win2 = 0;
            if (e[4] < e[6]) {
                win2 = e[4] * unit;
            }
            const result2 = win2 - unit;
            topdog[e[0]] = topdog[e[0]] == null ? result2 : (topdog[e[0]] + result2);
            topdogbalance += win2;
        });
        window.console.log("Underdog Total daily bet " + underdogbalance);
        window.console.log(underdog);
        window.console.log("Topdog Total daily bet " + topdogbalance);
        window.console.log(topdog);
    },
    clickSimulateAll() {
        const rows = [];
        let i = 0;
        this.teamsArray.forEach(team => {
            const row = this.analyze(team);
            const winRate = (row.win/row.total*100).toFixed(0) + '%(' + row.win + '/' + row.total + ')';
            const upsetRate = (row.totalUnderdog/row.total*100).toFixed(0) + '%(' + row.totalUnderdog + '/' + row.total + ')';
            const underdogRate = (row.upset/row.totalUnderdog*100).toFixed(0) + '%(' + row.upset + '/' + row.totalUnderdog + ')';
            rows.push([++i, team, winRate, upsetRate, underdogRate, row.maxLost, row.balance.toFixed(2), row.totalUnderdog]);
        });
        this.teamsData = rows.sort((a,b)=>{
            return b[7] - a[7];
        });
    },
    clickSimulate() {
        const result = this.analyze(this.selectTeam);
        this.logs = result.logs;
        this.total = result.total;
        this.win = result.win;
        this.upset = result.upset;
        this.totalUnderdog = result.totalUnderdog;
        this.maxLost = result.maxLost;
    },
    analyze(selected) {
        const filtered = this.betEvents.filter(row => row.filter(o => o == selected).length > 0);
        let total = 0, win = 0, upset = 0, totalUnderdog = 0;
        let logs = [];
        filtered.forEach((e) => {
            if (e[3] == selected) { win++;}
            if (e[3] == selected && e[4] > e[6]) upset++;
            if (e[3] == selected && e[4] > e[6] || e[5] == selected && e[6] > e[4]) totalUnderdog++;
            total++;
        });
        
        let i = 0, maxLost = 0, lost = 0, initUnitSize = this.initUnitSize, unitSize = this.initUnitSize, balance = this.initBalance;
        const underdogFilter = filtered.filter(row => 
            ((row[3] == selected) && (row[4] > row[6]) && (row[4] >= Number(this.oddsFrom)) && (row[4] <= Number(this.oddsTo))) 
            || ((row[5] == selected) && (row[6] > row[4]) && (row[6] >= Number(this.oddsFrom)) && (row[6] <= Number(this.oddsTo))));
        underdogFilter.forEach((e) => {
            if (e[3] == selected) {
                lost = 0;
                balance += ((unitSize * e[4]) - unitSize);
                unitSize = initUnitSize;
            } else {
                lost++;
                if (maxLost < lost) maxLost = lost;
                balance -= unitSize;
                unitSize *= 2;
            }
            let status = "lost";
            if (e[3] == selected) { status = "won"}
            logs.push([++i,status,balance, ...e]);
        });
        return { 
            total: total,
            win: win,
            upset: upset,
            totalUnderdog: totalUnderdog,
            maxLost: maxLost,
            balance: balance,
            logs: logs
        };
    },
    getRangedOdds(items) {
        const from = this.oddsFrom;
        const to = this.oddsTo;
        const filtered = items.filter(obj => obj.odds.filter(o => o.odds >= from && o.odds <= to).length > 0);
        return filtered;
    },
    reset() {
        this.simulationRows = [];
        this.logs = [];
        this.maxLost = 0;
        this.maxLostAmount = 0;
        this.maxWon = 0;
        this.maxWonAmount = 0; 
    },
    eventsRawDataReceived(response) {
        this.reset();

        const data = JSON.parse(response.data);
        this.setTableData(data.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return dateA - dateB;
        }));
        this.disableButton = false;
    },
    addTeam(team) {
        if (this.teams[team] == null) {
            this.teams[team] = 1;
        }
    },
    setTableData(arr) {
        let i = 0;
        arr.forEach((e) => {
            const game = e.games.find((g) => g != null && g.title == "Money Line");
            if (game != null) {
                const result = e.gameResults.find((g) => g.id == game.id);
                const winner = game.options.find((o) => o.id == result.resultGameOptionId);
                if (winner != null) {
                    const loser = game.options.find((o) => o.id != result.resultGameOptionId);
                    this.addTeam(winner.title);
                    this.addTeam(loser.title);
                    this.betEvents.push([this.convertUTCToPDTAndFormat(e.startDate), e.title, game.title, winner.title, winner.odds, loser.title, loser.odds]);
                }
            }
        });
        this.betEvents.forEach((e) => {
            this.simulationRows.push([++i,...e]);
        });
        this.teamsArray = Object.keys(this.teams).sort();
    },
    addExtra(events) {
        events.forEach(e => {
            e["date"] = this.convertUTCToPDTAndFormat(e.startDate);
        });
        return events;
    },
    findBiggestOdds(arr) {
        return arr.reduce((maxOddsObj, currentObj) => {
            return (currentObj.odds > maxOddsObj.odds) ? currentObj : maxOddsObj;
        });
    },
    getRandomNumber(max) {
       return Math.floor(Math.random() * max);
    },
    convertUTCToPDTAndFormat(utcDateString) {
        const utcDate = new Date(utcDateString);
        const pdtOffset = -7 * 60 * 60 * 1000;
        const pdtDate = new Date(utcDate.getTime() + pdtOffset);
        const formattedPdtDate = pdtDate.toISOString().split('T')[0];
        return formattedPdtDate;
    },
    groupByDate(inputArray) {
        const grouped = inputArray.reduce((acc, obj) => {
        // Extract the date and remove the time component
        const date = obj.date;

        // If the date is not in the accumulator, add a new array with the object
        if (!acc[date]) {
            acc[date] = [obj];
        }
        // If the date is in the accumulator, push the object into the existing array
        else {
            acc[date].push(obj);
        }

            return acc;
        }, {});
        // Convert the object to an array with date keys
        const result = Object.keys(grouped).map(date => ({
            date,
            items: grouped[date]
        }));

        return result;
    },
    getSimulatedList() {
        const simulatedList = [];
        this.betEvents.forEach((e) => {
            let found = null;
            e.odds.forEach((o) => {
                if (o.odds >= this.oddsFrom && o.odds <= this.oddsTo) {
                    found = o;
                }
            });
            if (found) {
                simulatedList.push({ startDate: e.startDate, odds: found, title: e.eventTitle});
            }
        });
        return simulatedList;
    },
    findConsistentGap(obj) {
        // Get the keys and convert them to an array of numbers
        const keys = Object.keys(obj).map(Number).sort((a, b) => a - b);

        // Check if there are at least two keys
        if (keys.length < 2) {
            return 0; // Return 0 for objects with 0 or 1 keys as there's no gap
        }

        // Calculate the consistent gap between the first two keys
        const gap = keys[1] - keys[0];

        // Verify if the gap is consistent for all consecutive keys
        for (let i = 2; i < keys.length; i++) {
            if (keys[i] - keys[i - 1] !== gap) {
            throw new Error("The keys do not have a consistent gap.");
            }
        }

        return gap;
    },
    getEndOfDate(datetime) {
        return this.removeTimeFromDateUTC(this.addDays(datetime, 1));
    },
    getNextWeekDay(date, d) {
        if (d) {
            var next = date;
            next.setDate(date.getDate() - date.getDay() + 7 + d);
            return next;
        }
    },
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    customFormatter(date) {
      return this.$moment(date).format('YYYYMMDD');
    },
    removeTimeFromDateUTC(datetime) {
        let date = new Date(datetime);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    },
  }
}
</script>
<style lang="scss">
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>